.mainDiv {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: antiquewhite;
}


/* To ensure that the navbar stays on top */
.navContainer {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
  background-color: antiquewhite;
}

.mynavbar {
  height: 50px;
  display: flex;
  justify-content: center;
}

/* To fill the page */
.mainContainer {
  width: 100vw;
  padding-top: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
}

.innerContainer {
  max-width: 800px;
  text-align: center;
}

.carousel-item {
  position: relative;
  height: calc(100vh - 50px);
}

.carousel-content {
  height: 100%;
  color: black;
  text-align: center;
  vertical-align: middle;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20vw;
  padding-right: 20vw;
}

.quoteList {
  text-align: center;
  padding-left: 5vw;
  padding-right: 5vw;
  width: 100%;
}

.listQuote {
  padding-top: 10px;
}

.secondLine {
  display: flex;
}

.listPerson {
  flex-grow: 10;
  padding-left: 50px;
  padding-bottom: 30px;
}

.listLink {
  flex-basis: 50px;
  font-size: smaller;
}

.addQuoteForm {
  padding: 20px;
}

.addQuoteButton {
  cursor: pointer;
}